import classNames from "classnames";
import Button from "~/components/common/Button";
import CodeTabs from "~/components/common/CodeTabs";
import Field from "~/components/common/Field";
import ErrorMessage from "~/components/common/ErrorMessage";

import Form from "~/components/common/Form";

import {capitalize, createStylesSelector, getIn} from "~/lib";
import {APISampleFormProps, APISampleValues} from "~/@types/components/forms/APISampleFormProps";
import {CodeProps} from "~/@types/components/common/CodeProps";
import Code from "~/components/common/Code";
import {CodeLanguages} from "~/@types";
import {
    handleRefresh as codeMirrorJsonSchemaHandleRefresh,
    jsonCompletion as codeMirrorJsonSchemaCompletion,
    jsonSchemaHover as codeMirrorJsonSchemaHover,
    jsonSchemaLinter as codeMirrorJsonSchemaLinter,
    stateExtensions as codeMirrorJsonSchemaStateExtensions,
    updateSchema as codeMirrorJsonSchemaUpdateSchema
} from "codemirror-json-schema";
import {linter as codeMirrorLinter} from "@codemirror/lint";
import {
    jsonLanguage as codeMirrorJsonLanguage,
    jsonParseLinter as codeMirrorJsonParseLinter
} from "@codemirror/lang-json";
import {hoverTooltip as codeMirrorHoverTooltip} from "@codemirror/view";

import {CodeTabsItem, CodeTabsItems} from "~/@types/components/common/CodeTabsProps";
import {useGetSchemaQuery} from "~/api/baseAPI";
import type {JSONSchema7} from "json-schema";
import {useEffect, useMemo, useRef} from "react";
import {EditorView} from "@uiw/react-codemirror";

function APISampleCodeRequestField({styles: propsStyles, name,  ...props}: CodeProps) {
    const styles = createStylesSelector([propsStyles]);
    return <Field classes={{}} styles={styles} name={`request.${name}`}>{({input, meta: {error, submitError, touched}, fieldName}) => <>
        <Code
            {...props}
            styles={propsStyles}
            name={fieldName}
            onChange={input.onChange}
            onFocus={input.onFocus}
            onBlur={input.onBlur}
        >
            {input.value}
        </Code>
        {submitError || touched && error ? <ErrorMessage classes={{}} styles={styles} className={"" + styles("request-error")}>{submitError || error}</ErrorMessage> : null}
    </>
    }</Field>;
}

function APISampleForm({
    name,
    loading,
    classes: propsClasses,
    styles: propsStyles,
    initialValues,
    ...props
}: APISampleFormProps) {
    const styles = createStylesSelector([propsClasses, propsStyles]);

    const itemLabels = {"www-form": "HTTP"};

    const {data: openapiSchema, isLoading: openapiLoading, error: openapiError} = useGetSchemaQuery();

    const schema: JSONSchema7 = useMemo(
        () => {
            let schema: JSONSchema7 = {
                type: "object",
                additionalProperties: true
            };
            if (!openapiLoading && !openapiError) {
                const oaSchema = openapiSchema?.components?.schemas?.["SchemasMediaRequestJSON"] as object;
                const resolveRef = (schema: object): object => {
                    let mergedSchema: object = {...schema};
                    if (schema["$ref"]) {
                        const componentPath = schema["$ref"].split("/");
                        componentPath.shift();
                        const resolved = getIn(openapiSchema as object, componentPath, null);
                        if (resolved !== null) {
                            mergedSchema = {...schema, ...resolved};
                            delete mergedSchema["$ref"];
                        }
                    }

                    Object.keys(mergedSchema).forEach(key => {
                        if (mergedSchema[key] && typeof mergedSchema[key] === "object") {
                            mergedSchema[key] = resolveRef(mergedSchema[key]);
                        }
                    });

                    return mergedSchema;
                };
                if (oaSchema) {
                    schema = resolveRef(oaSchema) as JSONSchema7;
                }
            }

            return schema;
        },
        [openapiSchema]
    );

    const jsonView = useRef<EditorView | undefined>(undefined);
    useEffect(() => {
        if (jsonView.current) {
            codeMirrorJsonSchemaUpdateSchema(jsonView.current, schema);
        }
    }, [schema]);

    const items: CodeTabsItems = Object.keys(initialValues.request).map(language => {
        const tabItem: CodeTabsItem = {
            name: language,
            key: language,
            label: itemLabels[language] || capitalize(language),
            editable: !loading && !openapiLoading,
            disabled: loading || openapiLoading,
            language: language as CodeLanguages,
            code: initialValues.request[language],
        };
        if (language === "json") {
            tabItem.onCreateEditor = (view: EditorView) => {
                jsonView.current = view;
            };
            if (!tabItem.extensions) {
                tabItem.extensions = [];
            }
            if (schema) {
                tabItem.extensions.push(
                    codeMirrorLinter(codeMirrorJsonParseLinter(), {
                        // default is 750ms
                        delay: 300
                    }),
                    codeMirrorLinter(codeMirrorJsonSchemaLinter(), {needsRefresh: codeMirrorJsonSchemaHandleRefresh}),
                    codeMirrorJsonLanguage.data.of({autocomplete: codeMirrorJsonSchemaCompletion()}),
                    codeMirrorHoverTooltip(codeMirrorJsonSchemaHover()),
                    codeMirrorJsonSchemaStateExtensions(schema)
                );
            }
        }

        return tabItem;
    });

    return <Form<APISampleValues>
        {...props}
        initialValues={initialValues}
        name={name || "api-sample-form"}
        classes={{}}
        styles={styles}
        className={classNames(props.className, styles("api-sample-form"))}
        mutators={{
            setFormat: ([activeKey], state, utils) => {
                utils.changeValue(state, "format", () => activeKey);
            }
        }}
    >
        {({form, submitting}) => <>
            <Field<APISampleValues["format"]> name="format" type="hidden" classes={{}} styles={styles}>
                {({input, meta: {fieldName}}) => <input {...input} name={fieldName}/>}
            </Field>
            <CodeTabs
                styles={styles}
                items={items}
                className={"" + styles("code-sample", "request-tabs")}
                tabBarExtraContent={{left: "Request"}}
                defaultActiveKey={"json"}
                prettyPrint
                CodeComponent={APISampleCodeRequestField}
                onTabSwitch={(activeKey: string) => form.mutators.setFormat(activeKey)}
                addCopy
            />

            <Button
                ant="default"
                className={"" + styles("submit")}
                bold
                disabled={loading || submitting}
                type="submit"
            >
                {loading ? "loading..." : "Submit!"}
            </Button>
        </>}
    </Form>;
}

export default APISampleForm;